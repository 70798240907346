import { InteractiveTutorial, InteractiveTutorialProps } from "@/features/interactive_tutorial";
import { Image, TourStepProps } from "antd";

const steps: TourStepProps[] = [
  {
    title: "Hide Fields",
    description: (
      <>
        <p>
          Click on &apos;<b>Hide Fields</b>&apos; to reveal toggles to hide or show table columns for ease of viewing.
        </p>
        <Image
          alt="Shows menu displayed when clicking the Hide Fields menu item."
          src="/_/img/tours/database/hide-fields.png"
        />
      </>
    ),
  },

  {
    title: "Filter",
    description: (
      <>
        <p>
          Click on &apos;<b>Filter</b>&apos;, and &apos;<b>+ Add condition</b>&apos; to add a conditional filter.
        </p>
        <Image alt="Shows menu displayed when clicking the Filter menu item." src="/_/img/tours/database/filter.png" />
      </>
    ),
  },

  {
    title: "Example: Filter",
    description: (
      <>
        <p>
          For example, the results below have been filtered by a time &apos;<b>Period</b>&apos; and &apos;
          <b>Data Source</b>&apos;, with time period set to later than 1988, and data provided by the Hong Kong
          Observatory.
          <ul>
            <li>
              Filter records by choosing a Field from the dropdown next to &apos;<b>Where</b>&apos;.
            </li>
            <li>
              In the second dropdown, choose an operator to sort by - ie. &apos;<b>is</b>&apos; or &apos;<b>contains</b>
              &apos;.
            </li>
            <li>In the third dropdown, choose an option or enter a value you wish to refine records by.</li>
            <li>
              Add more conditions by clicking &apos;<b>+ Add condition</b>&apos;, and applying &apos;<b>and</b>&apos; /
              &apos;<b>or</b>&apos; conditions to add more filters to results.
            </li>
            <li>Use the trash can icon to delete a filter.</li>
            <li>
              To refine even further, Click on &apos;<b>+ Add condition group</b>&apos; , and drag conditional line
              items via the ⠿ button. This allows you to overlay many conditional searches.
            </li>
          </ul>
        </p>
        <Image
          alt="A complicated filter is displayed, utilising all the options explained in the text above."
          src="/_/img/tours/database/example-filter.png"
        />
      </>
    ),
  },

  {
    title: "Group",
    description: (
      <>
        <p>
          Click &apos;<b>Group</b>&apos; to group data by field, and sort group results from &apos;
          <b>first - last</b>&apos;, or &apos;<b>last - first</b>&apos; order.
        </p>
        <Image alt="The Group menu item is displayed." src="/_/img/tours/database/group.png" />
      </>
    ),
  },

  {
    title: "Sort",
    description: (
      <>
        <p>
          &apos;<b>Sort</b>&apos; allows you to sort records by field.
        </p>
        <Image alt="The Sort option is displayed." src="/_/img/tours/database/sort.png" />
      </>
    ),
  },

  {
    title: "Row Height",
    description: (
      <>
        <p>Click on the row height icon to adjust row height in table window.</p>
        <Image
          alt="Row height is adjusted by the second-last option the tool menu."
          src="/_/img/tours/database/row-height.png"
        />
      </>
    ),
  },

  {
    title: "Source Link",
    description: (
      <>
        <p>
          Under column field &apos;<b>Source Link</b>&apos; on the furthest right of the table, you will find source
          links to external data.
        </p>
        <Image alt="Sources are in the Source Link column." src="/_/img/tours/database/source-link.png" />
      </>
    ),
  },

  {
    title: "Data",
    description: (
      <>
        <p>
          Where the data is available in an easily accessible format, it will appear in the &apos;<b>Data</b>&apos;
          column. Click on the thumbnails to expand the content to full screen view.
        </p>
        <Image alt="Data column may contain visual references." src="/_/img/tours/database/data.png" />
      </>
    ),
  },

  {
    title: "Download",
    description: (
      <>
        <p>Download a .csv of data for further reference.</p>
        <Image
          alt="Click triple fots menu item to reveal Download CSV option."
          src="/_/img/tours/database/download.png"
        />
      </>
    ),
  },
];

export const DatabaseTutorial = (props: InteractiveTutorialProps<Record<any, any>>) => {
  return <InteractiveTutorial<Record<any, any>> isWide={true} steps={steps} {...props} />;
};
