import { HomeDashboard } from "@/features/home_dashboard";

export const HkmaHomeDashboard = () => {
  return (
    <HomeDashboard
      disclaimer={
        <>
          This Platform was commissioned by the HKMA with the objective of assisting AIs in building up their capacity
          for physical risk assessment and management. The HKMA does not mandate AIs to use the Platform or the relevant
          model, methodology or solution. If AIs choose to use the Platform, they should seek to understand the data and
          methodology behind it. They should also assess whether the functionalities of the Platform suit their
          circumstances and evaluate the corresponding results accordingly.
        </>
      }
      banner={{
        image: {
          alt: "Skyline of Hong Kong with a purple overlay",
          src: "/_/home-banner.jpg",
          style: {
            opacity: 0.33,
            filter: "blur(3px)",
          },
        },
      }}
    />
  );
};
