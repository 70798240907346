import { Embed } from "@/features/embeds";
import { DatabaseOutlined } from "@ant-design/icons";
import type { Tool, ToolProps } from "@/types/tools";
import { Alert } from "antd";
import { DatabaseTutorial } from "./tutorial";

const HkmaDatabaseTool = (props: ToolProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // This just looks good, dw about it.
        height: "max(450px, 100vh - 207px)",
        marginBottom: "1rem",
      }}
    >
      <Embed
        data={{
          type: "airtable",
          slug: "appoYS8nmY30wjJ7g/shrCPwHIAdwIAaQA1",
        }}
        style={{
          flex: 1,
          width: "100%",
          marginBottom: "1rem",
        }}
      />
      <Alert
        description={
          <>
            <b>Disclaimer:</b> The HKMA has engaged a service provider to provide services in relation to this Platform.
            This database contains downloadable data which have been cleansed and/or transformed by the service
            provider, as well as links to external sources. Inclusion in the database does not indicate use of or
            endorsement by the HKMA.
          </>
        }
        type="info"
      />
      <DatabaseTutorial
        onClose={() => props.setIsTutorialOpen(false)}
        open={props.isTutorialOpen}
        setState={
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        }
      />
    </div>
  );
};

export default {
  id: "database",
  category: "reference",
  keyPrefix: "reference.database",
  maxWidth: "100%",
  icon: <DatabaseOutlined />,
  render: (props) => <HkmaDatabaseTool {...props} />,
  hasTutorial: true,
} as Tool;
