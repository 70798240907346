import { HubThemePartial } from "../../types";
import { merge } from "lodash/fp";
import hkmaThemeBase from "./_base";

const hkmaPreviewTheme: HubThemePartial = merge(hkmaThemeBase, {
  meta: {
    config: {
      featureSet: {
        tracking: {
          plausible: {
            enabled: true,
            allowLocalTracking: false,
            siteDomain: "preview.climaterisk.hk",
          },
        },
      },
    },
  },
} as HubThemePartial);

export default hkmaPreviewTheme;
